import React, { useEffect } from 'react'
import CorpLogo from './corp.logo.jpg'
// css
import './App.css'

const App = () => {
  useEffect(() => {
    document.title = 'Under Construction | 宣睿科技'
  }, [])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <img width='400px' height='400px' alt='Logo' src={CorpLogo} />
    </div>
  )
}

export default App
